export function isMobile() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

export function isIpad() {
  return /iPad/i.test(navigator.userAgent);
}

export function isIphone() {
  return ['iPhone Simulator', 'iPhone'].includes(navigator.platform);
}
